import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import courseType from '../prop-types/course';
import { MESSAGE_TYPES } from '../enums';
import { CourseProvider } from '../contexts/course';
import { MessageContext, MessageProvider } from '../contexts/message';
import { BrowserContext } from '../contexts/browser';
import CourseContainer from '../containers/Course';

const successHash = '#success';

const PurchaseConfirmationWrapper = ({ children }) => {
  const { hash } = useContext(BrowserContext);
  const { setMessage, setType } = useContext(MessageContext);

  useEffect(() => {
    if (hash === successHash) {
      setType(MESSAGE_TYPES.success);
      setMessage(
        'Vielen Dank für den Einkauf. Wir haben den Kurs zu Ihrem Account hinzugefügt und eine Bestätigungsemail ist auf dem Weg zu Ihnen.'
      );
    }
  }, [hash, setMessage, setType]);

  return <>{children}</>;
};

PurchaseConfirmationWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

const CoursePage = ({ data: { courses: course } }) => (
  <CourseProvider>
    <MessageProvider>
      <PurchaseConfirmationWrapper>
        <CourseContainer course={course} />
      </PurchaseConfirmationWrapper>
    </MessageProvider>
  </CourseProvider>
);

CoursePage.propTypes = {
  data: PropTypes.shape({
    courses: courseType.isRequired,
  }).isRequired,
};

export default CoursePage;

export const query = graphql`
  query($id: String!) {
    courses(id: { eq: $id }) {
      id
      title
      description
      duration
      imageText
      imageUrl
      previewUrl
      price
      link
      manualUrl
      videos {
        description
        imageUrl
        index
        title
        imageText
        reminder
      }
    }
  }
`;
