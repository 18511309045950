import React, { useMemo, useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { cloneDeep } from 'lodash';

import { LOADING_STATUS, ENV, STYLES } from '../../enums';
import { ReactComponent as ArrowDown } from '../../assets/svgs/download.svg';

import { resetVideoProgress } from '../../services/backend/user';
import { UserContext } from '../../contexts/user';
import useUserCourse from '../../hooks/useUserCourse';
import PurchasedVideo from '../../components/videoList/purchasedItem';
import Spinner from '../../components/spinner/spinner';
import Button from '../../components/clickables/button';
import Stack from '../../components/systems/stack';

import Grid from '../../components/systems/grid';
import CustomLink, { styles } from '../../components/clickables/customLink';
import {
  clearCourseCheckout,
  getCourseCheckout,
} from '../../utilities/storage';

const tag = 'PurchasedCourse';

const WelcomeMessage = styled.div`
  font-size: ${STYLES.fontSizes.s};
`;

const getPDFName = link => {
  const arr = link.split('/');
  return arr[arr.length - 1];
};

const PurchasedCourse = ({ courseId }) => {
  const { course, status, currentVideo } = useUserCourse(courseId);
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    const checkedOutId = getCourseCheckout();
    if (courseId === checkedOutId) {
      console.gtag('event', 'conversion', {
        send_to: 'AW-408515162/rPEvCPL_mIACENrk5cIB',
        transaction_id: '',
      });
      clearCourseCheckout();
    }
  }, [courseId]);

  const startedRewatch = useMemo(
    () =>
      course
        ? !!course.videos.filter(v => v.trackedProgress !== 1).length
        : false,
    [course]
  );

  const finshedLength = useMemo(
    () =>
      course ? course.videos.filter(v => v.trackedProgress === 1).length : 0,
    [course]
  );

  const handleReset = useCallback(() => {
    const optimisticUser = cloneDeep(user);
    optimisticUser.progress = user.progress.map(p => {
      if (p.course === course._id) {
        return {
          ...p,
          progress: 0,
        };
      }
      return p;
    });
    setUser(optimisticUser);
    resetVideoProgress(course._id);
  }, [course, setUser, user]);

  if (status !== LOADING_STATUS.hasSucceeded || !course || !currentVideo) {
    console.tag(tag).verbose('page still loading');
    return <Spinner />;
  }
  console.tag(tag).verbose('page loaded, currentVideo', currentVideo);
  console.tag(tag).verbose(`manual url: ${ENV.mediaPath}/${course.manualUrl}`);
  return (
    <Stack centered={false} gap="25px">
      <h1>{course.title}</h1>
      <CustomLink
        title="Seminar Manual herunterladen"
        link={`${ENV.mediaPath}/${course.manualUrl}`}
        download={getPDFName(course.manualUrl)}
        as={styles.asButton}
        newTab
      >
        <ArrowDown />
        Download Teilnehmermanual
      </CustomLink>
      <p>{course.description}</p>
      <Stack centered={false} gap="15px">
        <h2>Willkommen zurück!</h2>
        <WelcomeMessage>
          <Stack centered={false} gap="15px">
            {finshedLength && startedRewatch ? (
              <p>
                {`Super! Sie haben bereits ${finshedLength} ${
                  finshedLength === 1 ? 'Training' : 'Trainings'
                } abgeschlossen! `}
              </p>
            ) : (
              ''
            )}
            {(!course.finishedCourse || startedRewatch) && (
              <>
                <p>Machen Sie da weiter, wo Sie aufgehört hast:</p>
                <div>
                  <Button
                    label={currentVideo.title}
                    onClick={() =>
                      navigate(`/${course.link}/${currentVideo.index}`)
                    }
                    primary
                  />
                </div>
              </>
            )}
            {course.finishedCourse && !startedRewatch && (
              <>
                <p>{`Gratulation! Sie haben alle Trainings von ${course.title} abgeschlossen! Sie können das Seminar jederzeit zurücksetzen und von vorne anfangen!`}</p>
                <div>
                  <Button label="Zurücksetzen" onClick={handleReset} />
                </div>
              </>
            )}
          </Stack>
        </WelcomeMessage>
      </Stack>
      <Grid gap="40px">
        {course.videos.map(video => (
          <PurchasedVideo
            key={video.index}
            index={video.index}
            coursePath={course.link}
            title={video.title}
            description={video.description}
            src={video.imageUrl}
            alt={video.imageText}
            trackedProgress={video.trackedProgress}
            hasFinished={video.hasFinished}
          />
        ))}
      </Grid>
    </Stack>
  );
};

PurchasedCourse.propTypes = {
  courseId: PropTypes.string.isRequired,
};

export default PurchasedCourse;
