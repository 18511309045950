import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { navigate } from 'gatsby';
import STYLES from '../../enums/styles';
import Image from '../image/image';
import CustomButton from '../clickables/customButton';

const r = 26.25;
const c = Math.PI * (r * 2);
function progressToDashoffset(trackedProgress) {
  let val = 100 * trackedProgress;

  if (val < 0) {
    val = 0;
  }
  if (val > 100) {
    val = 100;
  }

  return ((100 - val) / 100) * c;
}

const ImgContainer = styled.div`
  position: relative;
  width: 28vw;
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    width: 90vw;
  }

  img {
    z-index: 1;
  }

  &:focus {
    transform: scale(1.003);
  }

  &:hover {
    transform: scale(1.003);
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background-color: ${STYLES.colors.white};
  opacity: 0.5;
`;

const Seen = styled.p`
  border-radius: 5px;
  background-color: ${STYLES.colors.mattWhite};
  font-size: ${STYLES.fontSizes.s};
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    font-size: ${STYLES.fontSizes.xs};
  }
  font-weight: ${STYLES.fontWeights.bold};
  z-index: 3;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px;
  color: ${STYLES.colors.success};
  margin: 0;
`;

const Circle = styled.circle`
  z-index: 3;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1s linear;
  stroke: ${STYLES.colors.white};
  stroke-width: 6px;
`;

const Bar = styled.circle`
  z-index: 3;
  stroke-dashoffset: ${props => progressToDashoffset(props.trackedProgress)};
  transition: stroke-dashoffset 1s linear;
  stroke-width: 6px;
  stroke: ${STYLES.colors.success};
`;

const ProgressBar = styled.svg`
  z-index: 3;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Play = styled.svg`
  z-index: 3;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 31.5px;
  height: 31.5px;
  fill: ${props =>
    props.hasFinished ? STYLES.colors.success : STYLES.colors.white};
`;

const PurchasedCoursePicture = ({
  src,
  alt,
  hasFinished,
  trackedProgress,
  buttonText,
  link,
}) => {
  const [delayedProgress, setDelayedProgress] = useState(0);
  const [delayedHasFinished, setDelayedHasFinished] = useState(false);

  const progressCircle = useMemo(
    () => (
      <ProgressBar
        xmlns="http://www.w3.org/2000/svg"
        width="62.5"
        height="62.5"
        viewPort="0 0 62.5 62.5"
        version="1.1"
      >
        <Circle
          r={r}
          cx="31.25"
          cy="31.25"
          fill="transparent"
          strokeDasharray="164.931"
        />
        <Bar
          trackedProgress={delayedProgress}
          r={r}
          cx="31.25"
          cy="31.25"
          fill="transparent"
          strokeDasharray="164.931"
        />
      </ProgressBar>
    ),
    [delayedProgress]
  );

  const playButton = useMemo(
    () => (
      <Play
        hasFinished={delayedHasFinished}
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="-2 -1 25 25"
      >
        <path d="M3 22v-20l18 10-18 10z" />
      </Play>
    ),
    [delayedHasFinished]
  );

  useEffect(() => {
    const timeout = setTimeout(() => setDelayedProgress(trackedProgress), 2000);
    return () => clearTimeout(timeout);
  }, [trackedProgress]);

  useEffect(() => {
    const timeout = setTimeout(() => setDelayedHasFinished(hasFinished), 3500);
    return () => clearTimeout(timeout);
  }, [hasFinished]);

  return (
    <CustomButton
      label={`Navigate to video ${buttonText}`}
      title={`Navigate to video ${buttonText}`}
      onClick={() => navigate(link)}
    >
      <ImgContainer className="clickable">
        {progressCircle}
        {playButton}
        {trackedProgress === 1 && <Overlay />}
        <Image src={src} alt={alt} />
        {hasFinished && <Seen>Angeschaut</Seen>}
      </ImgContainer>
    </CustomButton>
  );
};

PurchasedCoursePicture.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  trackedProgress: PropTypes.number.isRequired,
  hasFinished: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default PurchasedCoursePicture;
