import React, { useState, useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Stack from '../systems/stack';
import Button from '../clickables/button';
import VideoPlayer from '../player/player';
import { BrowserContext } from '../../contexts/browser';
import Checkbox from '../clickables/checkbox';
import { STYLES } from '../../enums';

const tag = 'PreviewDetails';

const Details = styled.div`
  width: 48vw;
  @media screen and (max-width: ${STYLES.breakpoints.padWidth}px) {
    width: 90vw;
  }

  p {
    margin: 0 0 4vh 0;
    text-align: justify;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    flex-direction: column;
  }
  justify-content: flex-start;
  margin: 4vh 0;
`;

const Header = styled.div`
  h1 {
    margin: 0 0 10px 0;
  }
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (min-width: ${STYLES.breakpoints.phoneWidth}px) {
    margin-right: 30px;
  }
`;

const PurchaseForm = styled.form`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  margin-left: auto;

  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 0;
  }

  button {
    @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
      margin: 40px 0 0 0;
    }
  }
`;

const CheckboxContainer = styled.div`
  .terms-checkbox {
    display: inline-flex;
    align-items: center;

    label {
      font-size: $font-size-xs;
      text-align: right;

      @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
        text-align: left;
      }
    }

    input {
      width: auto;
      margin: 0 15px 0 0;
    }
  }
`;

const PreviewDetails = ({
  title,
  duration,
  priceCents,
  onCheckout,
  description,
  isLoading,
  previewUrl,
  courseId,
}) => {
  const { isPad } = useContext(BrowserContext);
  const [checked, setChecked] = useState(false);
  const [progress, setProgress] = useState(0);
  const [hasTracked, setHasTracked] = useState(false);

  const handleCheckboxChange = useCallback(() => {
    setChecked(!checked);
  }, [checked]);

  useEffect(() => {
    if (
      !hasTracked &&
      progress > 0.5 &&
      courseId === '5f552cbfb434342c996eab40'
    ) {
      console.gtag('event', 'conversion', {
        send_to: 'AW-408515162/RkqVCJKiuYkCENrk5cIB',
      });
      setHasTracked(true);
    }
  }, [courseId, progress, hasTracked]);

  const handleProgress = useCallback(p => {
    setProgress(p);
  }, []);

  return (
    <Details>
      <Stack gap="20px" centered={false}>
        <VideoPlayer
          index={0}
          link={previewUrl}
          latestProgress={0}
          onProgress={handleProgress}
          widthPercentage={isPad ? 0.9 : 0.48}
        />
        <TextContainer>
          <Header>
            <h1>{title}</h1>
            <span>{duration}</span>
          </Header>
          <PurchaseForm onSubmit={onCheckout}>
            <Stack gap="10px" centered={false}>
              <Button
                label={`Für ${priceCents * 0.01}€ kaufen`}
                isLoading={isLoading}
                submit
              />
              <CheckboxContainer>
                <Checkbox
                  id="accept-terms"
                  label={
                    <>
                      Ich stimme den
                      <a
                        href="/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' AGBs '}
                      </a>
                      zu.
                    </>
                  }
                  onChange={handleCheckboxChange}
                  checked={checked}
                  required
                />
              </CheckboxContainer>
            </Stack>
          </PurchaseForm>
        </TextContainer>
        <p>{description}</p>
      </Stack>
    </Details>
  );
};

PreviewDetails.propTypes = {
  title: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  priceCents: PropTypes.number.isRequired,
  onCheckout: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  previewUrl: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
};

export default PreviewDetails;
