import React, { useCallback, useContext, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { navigate } from 'gatsby';
import styled from 'styled-components';

import { EVENTS } from '../../shared/enums';
import { ENV } from '../../enums';
import courseType from '../../prop-types/course';

import { checkoutCourse } from '../../services/backend/user';
import {
  clearWantedToBuy,
  getWantedToBuy,
  setCourseCheckout,
  setOnLoginRediret,
  setWantedToBuy,
} from '../../utilities/storage';
import { BrowserContext } from '../../contexts/browser';
import useIsLoggedIn from '../../hooks/useIsLoggedIn';
import PreviewDetails from '../../components/course/previewDetails';
import PreviewVideo from '../../components/videoList/previewItem';
import Grid from '../../components/systems/grid';
import { CookieContext } from '../../contexts/cookie';

const tag = 'PreviewCourse';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PreviewCourse = ({ course }) => {
  console.tag(tag).debug('course', course);
  const { acceptedCookies } = useContext(CookieContext);
  const { pathname } = useContext(BrowserContext);
  const isLoggedIn = useIsLoggedIn();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // sh
    if (acceptedCookies) {
      if (course.id === '5f552cbfb434342c996eab40') {
        console.gtag('event', 'conversion', {
          send_to: 'AW-408515162/sctSCM6LgoACENrk5cIB',
          value: 0.0,
          currency: 'EUR',
        });
      }
    }
  }, [course.id, acceptedCookies]);

  const handleCheckout = useCallback(
    async e => {
      if (e) {
        e.preventDefault();
      }

      if (!isLoggedIn) {
        setOnLoginRediret(pathname);
        setWantedToBuy(course.id);
        navigate('/signup');
        return;
      }
      setIsLoading(true);
      console.track(EVENTS.userCheckedOutACourse);
      setCourseCheckout(course.id);
      const { session } = await checkoutCourse(course.id);
      console.tag(tag).debug(session);
      const stripe = await loadStripe(ENV.stripePublicKey);
      stripe
        .redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: session.id,
        })
        .then(result => {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          if (result.error) {
            console.tag(tag).error(result.error.message);
          }
          setIsLoading(false);
          // we dont have to do anything on case of success as:
          // when we have success, our server already got the webhook trigger
          // which means after redirect, we fetch the new user object which got the course in it!
        });
    },
    [course.id, isLoggedIn, pathname]
  );

  useEffect(() => {
    const wantedToBuyCourseId = getWantedToBuy();
    if (wantedToBuyCourseId === course.id) {
      clearWantedToBuy();
      handleCheckout();
    }
  }, [handleCheckout, course.id]);

  return (
    <Container>
      <PreviewDetails
        title={course.title}
        description={course.description}
        duration={course.duration}
        priceCents={course.price}
        onCheckout={handleCheckout}
        isLoading={isLoading}
        previewUrl={course.previewUrl}
        courseId={course.id}
      />

      <Grid gap="40px">
        {course.videos.map(video => (
          <PreviewVideo
            key={video.index}
            title={video.title}
            description={video.description}
            src={video.imageUrl}
            alt={video.imageText}
          />
        ))}
      </Grid>
    </Container>
  );
};

PreviewCourse.propTypes = {
  course: courseType.isRequired,
};

export default PreviewCourse;
