import React from 'react';

import courseType from '../../prop-types/course';

import useUserHasCourse from '../../hooks/useUserHasCourse';
import PreviewCourse from './PreviewCourse';
import PurchasedCourse from './PurchasedCourse';

const CourseContainer = ({ course }) => {
  const userHasCourse = useUserHasCourse(course.id);

  return (
    <>
      {userHasCourse ? (
        <PurchasedCourse courseId={course.id} />
      ) : (
        <PreviewCourse course={course} />
      )}
    </>
  );
};

CourseContainer.propTypes = {
  course: courseType.isRequired,
};

export default CourseContainer;
