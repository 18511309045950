import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Image from '../image/image';
import { STYLES } from '../../enums';
import Stack from '../systems/stack';

const Video = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 4vh 2vw 4vh 0;
  width: 28wv;
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    margin: 4vh 2vw 4vh 0;
    width: 90vw;
  }

  h2 {
    text-transform: uppercase;
  }
`;

const ImgContainer = styled.div`
  position: relative;
  width: 28vw;
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    width: 90vw;
  }
  margin-bottom: 20px;
  &:focus {
    transform: scale(1.003);
    outline-color: ${({ theme }) => theme.outline.color};
  }
  &:focus-within {
    transform: scale(1.003);
    outline-color: ${({ theme }) => theme.outline.color};
  }
  &:hover {
    transform: scale(1.003);
  }
`;

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  z-index: 2;
  :hover {
    opacity: 1;
    outline-color: ${({ theme }) => theme.outline.color};
  }
  :focus {
    opacity: 1;
    outline-color: ${({ theme }) => theme.outline.color};
  }
  :focus-within {
    opacity: 1;
    outline-color: ${({ theme }) => theme.outline.color};
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${STYLES.colors.white};
  opacity: 0.5;
`;

const BuyToClick = styled.p`
  width: 300px;
  border-radius: 5px;
  font-size: ${STYLES.fontSizes.s};
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    font-size: ${STYLES.fontSizes.xs};
    width: 80vw;
  }
  font-weight: ${STYLES.fontWeights.bold};
  z-index: 4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  padding: 5px;
  background-color: ${STYLES.colors.mattWhite};
  color: ${STYLES.colors.success};
`;

const PreviewVideo = ({ title, description, src, alt }) => (
  <Video>
    <ImgContainer>
      <Image src={src} alt={alt} />
      <OverlayContainer tabIndex={0}>
        <Overlay />
        <BuyToClick>Kaufe den Kurs, um das Video anzuschauen</BuyToClick>
      </OverlayContainer>
    </ImgContainer>
    <Stack centered={false} gap="10px">
      <h2>{title}</h2>
      <p>{description}</p>
    </Stack>
  </Video>
);

PreviewVideo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default PreviewVideo;
