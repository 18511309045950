import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PurchasedCoursePicture from '../picture/purchasedCourse';
import { STYLES } from '../../enums';
import Stack from '../systems/stack';

const Video = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 4vh 2vw 4vh 0;
  width: 28wv;
  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    margin: 4vh 2vw 4vh 0;
    width: 90vw;
  }

  h2 {
    text-transform: uppercase;
  }
`;

const PurchasedVideo = ({
  coursePath,
  title,
  description,
  src,
  alt,
  index,
  trackedProgress,
  hasFinished,
}) => (
  <Video>
    <Stack centered={false} gap="20px">
      <PurchasedCoursePicture
        src={src}
        alt={alt}
        trackedProgress={trackedProgress}
        hasFinished={hasFinished}
        buttonText={`Navigate to video ${title}`}
        link={`/${coursePath}/${index}`}
      />
      <h2>{title}</h2>
      <p>{description}</p>
    </Stack>
  </Video>
);

PurchasedVideo.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  coursePath: PropTypes.string.isRequired,
  trackedProgress: PropTypes.number.isRequired,
  hasFinished: PropTypes.bool.isRequired,
};

export default PurchasedVideo;
